<template>
    <LayoutNext>
        <template #page-title>
            Pex Collections
        </template>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="d-md-flex d-block justify-content-between py-3 px-0">
                <div class="d-flex justify-content-start gap-1 mb-md-0 mb-3">
                    <MDBBtn class="fw-bold px-3 py-2" size="sm" color="primary" @click="createCollectionModal = true">
                        <MDBIcon icon="folder-plus" iconStyle="fas" class="me-1" />
                        Create Collection
                    </MDBBtn>
                    <MDBBtn class="fw-bold px-3 py-2" size="sm" color="primary" @click="goToAsset">
                        <MDBIcon icon="plus-square" class="me-1" />
                        Add Asset(s)
                    </MDBBtn>
                </div>
                <div class="d-flex justify-content-end">
                    <MDBInput placeholder="Search Pex Collection" type="text" v-model="searchInput" inputGroup
                        :formOutline="false">
                        <span class="input-group-text border-start-0" id="search-addon">
                            <em class="fas fa-search" />
                        </span>
                    </MDBInput>
                </div>
                <MDBModal tag="form" id="createCollectionModal" tabindex="-1" labelledby="CreateCollectionLabel"
                    v-model="createCollectionModal" :static-backdrop="true">
                    <MDBModalHeader>
                        <MDBModalTitle class="fw-bold" id="CreateCollectionLabel">
                            Create Collection
                        </MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody class="p-4 pt-3">
                        <p>You can manually add Assets to a Collection.</p>
                        <MDBInput class="mb-3" placeholder="Enter Collection Name *" type="text"
                            v-model="collectionName" required invalidFeedback="Please provide your first name" />
                        <MDBInput placeholder="Enter Reference ID" type="text" v-model="referenceId" />
                    </MDBModalBody>
                    <MDBModalFooter class="py-3 gap-2">
                        <MDBBtn class="fw-bold" size="sm" @click="createCollectionModal = false">Close</MDBBtn>
                        <MDBBtn class="fw-bold" type="button" color="primary" size="sm" :disabled="collectionName == ''"
                            @click.prevent.stop="createCollection" v-if="!isCreateLoading">
                            Create Collection
                        </MDBBtn>
                        <MDBBtn class="fw-bold" color="primary" size="sm" disabled v-else>
                            Creating...
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column p-0">
                <div class="overflow-auto mt-3" id="table-container" ref="el">
                    <MDBTable hover class="align-middle">
                        <thead class="table-light">
                            <tr>
                                <th class="sticky-top" scope="col">Collection Name</th>
                                <th class="sticky-top" scope="col" width="150">Asset Count</th>
                                <th class="sticky-top" scope="col" width="150">Creation Date</th>
                                <th class="sticky-top" scope="col" width="150">Last Updated</th>
                                <th class="sticky-top" scope="col" width="200"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="isLoading">
                                <tr>
                                    <td colspan="5" class="text-center">
                                        <div class="image-container d-flex justify-content-center align-items-center">
                                            <div class="loader"></div>
                                            <img src="@/assets/cs-loader.png" alt="Loading image">
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr v-if="collectionsList.length === 0">
                                    <td colspan="5" class="text-center">No collection found.</td>
                                </tr>
                                <tr class="row-action" v-for="(collection, index) in collectionsList" :key="index"
                                    @click="goToCollectionsAssets(collection.collectionIdString)" title="view asset">
                                    <td>{{ collection.title }}</td>
                                    <td>{{ collection.assetsCount }}</td>
                                    <td class=text-nowrap>{{ parseDateLong(collection.createdAt) }}</td>
                                    <td class=text-nowrap>{{ collection.updatedAt ? parseDateLong(collection.updatedAt) : '-' }}</td>
                                    <td>
                                        <MDBBtn class="fw-bold text-nowrap" color="primary" size="sm"
                                            @click.prevent="goToCollectionsAssets(collection.collectionIdString)">
                                            <MDBIcon icon="eye" class="me-1" />
                                            View Assets
                                        </MDBBtn>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </MDBTable>
                </div>
            </MDBCardBody>
            <MDBToast v-model="toast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
                color="success" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Success </template>
                Collection Created Successfully
            </MDBToast>
            <MDBToast v-model="toastError" :delay="2000" autohide position="top-right" appendToBody stacking
                color="danger" width="350px" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Failed </template>
                Collection Name already Exist!
            </MDBToast>
        </MDBCard>
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardHeader, MDBTable, MDBInput, MDBBtn, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBToast, MDBIcon } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { GetCollections } from "@/services/Collections/GetCollections";
import { PostCollection } from "@/services/Collections/PostCollection";
import { parseDateLong } from "@/helpers/parseDate";
import { useInfiniteScroll } from "@vueuse/core";
import { useTitle } from "@vueuse/core";

useTitle("Pex Collections | Creator Shield");

const isLoading = ref(true);
const searchInput = ref();

watch(searchInput, async (newVal) => {
    isLoading.value = true;
    try {
        if (newVal) {
            collectionsList.value = await getCollections(null, newVal);
        } else {
            collectionsList.value = await getCollections();
        }
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
});

const createCollectionModal = ref(false);
const collectionName = ref("");
const referenceId = ref("");
const isCreateLoading = ref(false);

const createCollection = async () => {
    isCreateLoading.value = true;
    const formBody = {
        title: collectionName.value,
        referenceId: referenceId.value,
    };
    try {
        const response = await PostCollection(formBody);
        if (response.errors) {
            toastError.value = true;
        } else {
            createCollectionModal.value = false;
            toast.value = true;
            collectionsList.value = await getCollections();
        }
    } catch (error) {
        console.error(error);
        toastError.value = true;
    } finally {
        isCreateLoading.value = false;
    }
};

watch(createCollectionModal, () => {
    collectionName.value = "";
    referenceId.value = "";
});

const router = useRouter();
const goToCollectionsAssets = (id) => {
    router.push({ name: "CollectionsAssets", params: { id: id } });
};
const goToAsset = () => {
    router.push({ name: "AssetsUpload" });
};

const collectionsList = ref([]);

const getCollections = async (lastItem, search) => {
    isLoading.value = true;
    const response = await GetCollections(lastItem, search);
    return response;
};

const toast = ref(false);
const toastError = ref(false);

onMounted(async () => {
    collectionsList.value = await getCollections();
    isLoading.value = false;
});

const el = ref();
const stopScrollLoad = ref(false);
useInfiniteScroll(
    el,
    async () => {
        if (
            isLoading.value ||
            stopScrollLoad.value ||
            collectionsList.value.length === 0
        ) {
            return;
        }
        isLoading.value = true;
        const currentArr = [...collectionsList.value];
        const lastItem = currentArr.pop();
        const response = await GetCollections(
            lastItem.collectionIdString,
            searchInput.value
        );
        if (response.length === 0) {
            stopScrollLoad.value = true;
            isLoading.value = false;
            return;
        }
        collectionsList.value = collectionsList.value.concat(response);
        isLoading.value = false;
    },
    { distance: 10 }
);
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

th {
    text-transform: uppercase;
    text-wrap: nowrap
}

tr {
    cursor: default;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}

#table-container {
    flex: 1 1 auto;
    height: 55vh;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
